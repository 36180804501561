/* .onsite-main {
  min-height: 70vh;
  min-width: 480px;
  width: fit-content;
  padding: 20px 50px;
  background-color: white;
  border-radius: 10px;
  margin: auto;
  box-shadow: 5px 5px 5px rgb(0 0 0 / 20%);
} */

hr {
	margin: 10px 0px;
	color: blue;
}

.onsite select {
	width: 100px;
}

.onsite h2 {
	text-align: center;
}

.employee {
	background-color: rgb(192, 250, 104);
	border-radius: 20px;
	border: none;
	width: 450px;
	margin-bottom: 15px;
	padding: 15px;
	display: flex;
	align-items: center;
	text-align: left;
}

.employee h3 {
	display: inline-block;
	margin-bottom: 0;
}

.name {
	font-weight: normal;
	color: blue;
}

.no-employees {
	margin-top: 20px;
	text-align: center;
	font-style: italic;
}

.mac {
	color: grey;
	font-style: italic;
}

.user-icon {
	font-size: 40px;
	padding: 0px 10px;
	color: black;
}

.id {
	font-weight: bold;
}

.since {
	margin-left: auto;
	font-style: italic;
	color: black;
}
