.header {
	background-color: rgb(12, 138, 234);
	/* background: linear-gradient(to right, rgb(12, 138, 234), rgb(8, 70, 117)); */
}

.header-row {
	display: flex;
	width: fit-content;
	margin: auto;
	align-items: center;
	margin-bottom: 20px;
}

.header h1 {
	color: white;
}

.navbar li {
	padding: 0px 20px;
	list-style: none;
}

.company-name {
	margin-left: 30px;
	font-weight: bold;
	font-style: italic;
}

.logout-link {
	margin-left: 40px;
	list-style: none;
}

.logout-link div {
	background-color: white;
	border-radius: 10px;
	padding: 9px;
	border: red 1px solid;
}

.link {
	color: white;
	text-decoration: none;
}

.link:hover {
	text-decoration: underline;
	cursor: pointer;
}

.active {
	/* text-decoration: underline; */
	font-weight: bold;
}

.login-icon {
	position: relative;
	top: 3px;
}

.logout {
	color: red;
}

.logout:hover {
	cursor: pointer;
}

ul {
	display: flex;
}
