.alert-main h4 {
	margin-top: 20px;
}

.title-box,
.message-box {
	width: 600px;
	font-size: 14px;
}

.alert-btn {
	display: block;
	margin: auto;
	margin-top: 30px;
}

.locations-parent {
	display: flex;
	flex-wrap: wrap;
	max-width: 580px;
	border: 1px grey solid;
	margin-top: 10px;
	border-radius: 10px;
	padding: 10px;
}

.location-btn {
	padding: 10px;
	margin-right: 10px;
	border-radius: 10px;
	margin-top: 5px;
	align-items: center;
	justify-content: center;
}

.location-selected {
	padding: 10px;
	margin-right: 10px;
	background-color: rgb(16, 168, 16);
	color: white;
	border: 2px solid transparent; /* transparent so stays same size */
}

.location-unselected {
	display: block;
	background-color: white;
	border: 2px solid grey;
}

.check-icon {
	align-items: center;
	justify-content: center;
	position: relative;
	top: 5px;
	font-size: 20px;
}
