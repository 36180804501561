/* shared css put here */

/* get font */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

* {
	margin: 0;
	font-family: montserrat, sans-serif;
}

option {
	font-family: montserrat, sans-serif;
}

button:hover {
	cursor: pointer;
}

.app {
	background-color: rgb(221, 221, 221, 0.7);
	min-height: 100vh;
}

main {
	min-height: 70vh;
	min-width: 430px;
	width: fit-content;
	padding: 20px 50px;
	background-color: white;
	border-radius: 10px;
	margin: auto;
	box-shadow: 5px 5px 5px rgb(0 0 0 / 20%);
}

.inpt-text {
	border: 1px blue solid;
	border-radius: 10px;
	padding: 10px;
	margin-left: 10px;
}

.btn {
	color: white;
	background-color: blue;
	border: none;
	border-radius: 10px;
	font-weight: bold;
	padding: 10px 15px;
}

.error {
	margin-top: 20px;
	text-align: center;
	color: red;
}

.success {
	margin-top: 20px;
	text-align: center;
	color: green;
}

.green {
	color: green;
}

.blue {
	color: blue;
}

.amber {
	color: rgb(255, 183, 0);
}

.red {
	color: red;
}

.italic {
	font-style: italic;
}

.bold {
	font-weight: bold;
}

.title {
	justify-content: center;
	align-items: center;
	display: flex;
}

.title h2 {
	margin-right: 10px;
	color: black;
}

.title select {
	border: 1px blue solid;
	border-radius: 10px;
	width: 150px;
	height: 40px;
	text-align: center;
	font-size: large;
}

textarea {
	resize: none;
}

.refresh {
	position: relative;
	display: block;
	color: blue;
	border: none;
	background: none;
	padding: 10px;
}

.btn-clicked {
	color: blue;
	background-color: white;
	border: 1px blue solid;
}

.btn-disabled {
	color: grey;
	background-color: white;
	border: 1px grey solid;
}

.btn-disabled:hover {
	cursor: default;
}

.icon-bigger {
	font-size: 18px;
}

/* table styling */

table {
	border-collapse: collapse;
	text-align: left;
	min-width: 700px;
}

th {
	background-color: rgb(12, 138, 234);
	color: white;
}

tr {
	border: 1px solid grey;
}

tr:nth-child(even) {
	background-color: #f2f2f2;
}

td,
th {
	padding: 15px;
}

.no-logs {
	text-align: center;
	font-style: italic;
}
