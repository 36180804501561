.info-group {
	margin-top: 20px;
}

.top {
	display: flex;
	align-items: center;
}

.back-btn {
	margin-left: auto;
}
