.login-form {
	margin: auto;
	padding-bottom: 30px;
	width: 380px;
	box-shadow: 5px 5px 5px rgb(0 0 0 / 20%);
	border-radius: 10px;
	background-color: white;
}

.login-form h2 {
	padding-top: 30px;
	text-align: center;
}

.login-form label {
	width: 300px;
	display: block;
	margin: auto;
}

.login-form input {
	width: 300px;
	height: 2em;
	font-size: 16px;
	margin-bottom: 30px;
	margin-top: 5px;
}

.btn-login {
	margin: auto;
	display: block;
	height: 40px;
	width: 300px;
	border-radius: 4px;
}

.btn-verifying {
	margin: auto;
	display: block;
	height: 40px;
	width: 300px;
	border: 1px blue solid;
	background-color: white;
	color: blue;
	border-radius: 4px;
}
