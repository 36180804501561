.location-overview {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}

.overview {
	border: 3px grey solid;
	border-radius: 10px;
	padding: 10px;
	text-align: center;
	min-width: 200px;
	box-sizing: border-box;
}

.overview-container {
	display: flex;
}

.welcome-msg {
	margin-bottom: 30px;
	color: blue;
}

.total-emps {
	border: 3px black solid;
	width: 300px;
	margin-top: 20px;
	margin-bottom: 20px;
}

.pinned-container {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	max-width: 1110px;
	min-width: 900px;
}

.emp {
	background-color: rgb(192, 250, 104);
	border-radius: 20px;
	border: none;
	min-width: 250px;
	width: 270px;
	padding: 15px;
	display: flex;
	align-items: center;
	text-align: left;
	position: relative;
}

.offline-emp {
	background-color: rgba(199, 199, 199, 0.458);
	border-radius: 20px;
	padding: 15px;
}

.un-pin-icon {
	color: red;
	font-size: 24px;
	position: absolute;
	top: 10px;
	right: 10px;
}

.dashboard-inpt {
	width: 300px;
}

.add-container {
	position: relative;
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}

.last-updated {
	display: flex;
	align-items: center;
	margin-left: auto;
}

.search-results-dropdown {
	border: 1px solid blue;
	border-top: none;
	background-color: rgba(174, 224, 241, 0.9);
	width: 320px;
	position: absolute;
	left: 210px;
	top: 38px;
	z-index: 1; /* Ensure it is above other elements */
	display: none; /* Hide by default */
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	/* make scrollable */
	max-height: 200px;
	overflow-y: auto; /* Enable vertical scrolling */
}

.search-results-dropdown p {
	padding: 15px;
	color: black;
}

.search-results-dropdown p:not(.no-res):hover {
	background-color: white;
	cursor: pointer;
}

/* Display dropdown when input is focused */
.add-container input:focus + .search-results-dropdown {
	display: block;
}
