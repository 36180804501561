.incident-overview {
	display: flex;
	gap: 20px;
	margin-top: 10px;
	justify-content: center;
}

.incident-overview-box {
	border: 3px grey solid;
	border-radius: 10px;
	padding: 10px;
	text-align: center;
	width: 300px;
	margin-bottom: 20px;
}

.incident {
	margin-top: 10px;
	border: solid 1px black;
	border-radius: 10px;
	padding: 15px;
	display: block;
	width: 430px;
	background-color: white;
	text-align: left;
}

.create-incident {
	align-items: center;
	justify-content: center;
	display: flex;
	margin: auto;
	margin-top: 10px;
	margin-bottom: 20px;
	border: solid 3px blue;
	color: blue;
	border-radius: 10px;
	padding: 15px;
	width: 670px;
	background-color: white;
}

.incidents-table {
	width: 1200px;
}

.action-btn {
	margin-right: 10px;
	font-weight: normal;
	padding: 7px;
}

.aqua-bg {
	background-color: rgb(4, 212, 212);
}

.eye-icon {
	position: relative;
	top: 3px;
	margin-right: 3px;
}

.red-bg {
	background-color: rgb(239, 16, 16);
}

/* styling for view incident page */

.recent-logs-heading {
	margin-top: 20px;
}

/* styling for create incidents page/form */

.create-incident-icon {
	font-size: 30px;
	margin-right: 5px;
}

.create-incident-main label {
	display: block;
}

.create-incident-main label {
	display: block;
	margin-bottom: 10px;
}

.create-incident-inpt-text {
	display: block;
	width: 400px;
}

.create-incident-top {
	display: flex;
	align-items: center;
}

.create-incident-btn:hover {
	cursor: pointer;
}

.btn-create-incident {
	display: block;
	margin: auto;
	margin-top: 20px;
}

.date-select {
	display: inline-block;
	width: 100px;
}

.to {
	display: inline-block;
	margin: 0 10px;
}

/* styling for action page */

.create-action input,
.create-action textarea {
	margin-bottom: 10px;
}

.create-action {
	border: 3px blue solid;
	border-radius: 10px;
	padding: 15px;
	margin-bottom: 20px;
}

.create-action button {
	display: block;
	margin: auto;
	margin-top: 10px;
}

.action-history-container {
	border: 1px black solid;
	padding: 10px;
	border-radius: 10px;
	margin-bottom: 10px;
	width: 500px;
}

.action-history-container span {
	font-weight: bold;
}

.action-history-container h4 {
	margin-top: 20px;
}
