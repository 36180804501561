.config-location-container {
	padding: 10px;
	border: 1px solid grey;
	border-radius: 10px;
	margin-bottom: 10px;
}

.config-location-container label {
	display: flex;
	align-items: center;
}

.config-location-container input[type='checkbox'] {
	width: 20px;
	height: 20px;
	margin-left: 10px;
	accent-color: green;
}

.create-admin-heading {
	margin-top: 30px;
}

.create-admin-form {
	border: 1px solid black;
	border-radius: 10px;
	padding: 20px;
}

.create-admin-form button {
	display: block;
	margin: auto;
}

.create-admin-form label {
	display: block;
	width: 80%;
	margin: auto;
	/* margin-left: 50px; */
}

.create-admin-form input {
	display: block;
	margin-bottom: 10px;
	width: 100%;
	margin: auto;
	margin-bottom: 15px;
	padding: 5px 0px;
}
