.row-info {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: white;
	border-radius: 10px;
	padding: 0px 20px;
	margin: 10px 0px;
}

.row-info select {
	border: 1px blue solid;
	border-radius: 10px;
	height: 40px;
	text-align: center;
}

.row-info label {
	display: inline;
	margin-right: 10px;
}

.refresh-symbol {
	font-size: 25px;
}

.search {
	border: 1px grey solid;
	border-radius: 10px;
	padding: 25px;
}

.search-top {
	display: flex;
	align-items: center;
}

.search label {
	margin: 0px 10px;
}

.search input,
.search select {
	border: 1px blue solid;
	border-radius: 10px;
	padding: 10px;
}

.btn-search {
	margin: 0px 10px;
}

.btn-filters {
	margin-left: auto;
}

.filter-options div {
	margin: 10px 0px;
}

.arrive {
	background-color: rgb(188, 250, 188);
}

.left {
	background-color: rgb(250, 176, 176);
}

.pages {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}

.page {
	border-radius: 10px;
	padding: 10px 17px;
	border: 2px grey solid;
	background-color: white;
	color: grey;
	margin-right: 10px;
}

.page:hover {
	cursor: pointer;
}

.active-page {
	border: 2px blue solid;
	background-color: blue;
	color: white;
}

.location-select {
	display: inline;
	width: 100px;
}

.download-link {
	text-decoration: underline;
	color: blue;
	border: none;
	background: none;
}

.share-logs {
	position: relative;
}

.share-dropdown {
	right: 0px;
	padding: 15px;
	position: absolute;
	background-color: white;
	width: 490px;
	border: 3px blue solid;
	border-radius: 10px;
	overflow: hidden;
}

.share-dropdown input {
	width: 270px;
	margin-left: 10px;
	margin-right: 10px;
	padding: 5px 0px;
}

.share-link {
	display: block;
	padding: 10px;
	text-decoration: none;
	color: black;
}

.share-link:hover {
	background-color: blue;
	color: white;
	cursor: pointer;
}

.close-dropdown {
	display: block;
	border: none;
	color: white;
	background-color: red;
	padding: 8px;
	border-radius: 5px;
	margin: auto;
}
